a {
  text-decoration: none;
  color: rgb(var(--colorTextBody));
}

.container {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  padding-right: 15px;
  padding-left: 15px;
  /* margin-top: 40px; */
  /* margin-top: -40px; */
  padding-bottom: 220px;
  /* padding-bottom: var(--spaceXL); */
  opacity: 0;
  transition: opacity var(--durationXL) ease var(--durationM);
}

.container--entered {
  opacity: 1;
}

.row {
  display: flex;
  justify-content: space-between;
}

.col {
  width: 32%;
}

@media (max-width: 576px) {
  .container {
    /* width: 90%; */
    padding-bottom: 60px;
  }

  .row {
    display: block;
  }
  .row .col {
    width: 100%;
    margin: 0 0 10px 0;
  }
  .otherProjHeading {
    text-align: center;
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 1.5rem 0 1.5rem;
  font-weight: 700;
  line-height: 1.5;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
}

.card {
  background: rgb(var(--rgbBackgroundLight));
  margin-top: 1.5rem;
  overflow: hidden;
  cursor: pointer;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  transition: box-shadow 0.3s ease-in-out;
  height: 300px;
}
.card:hover {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.card:focus {
  -webkit-box-shadow: 0 0 0 3px #22223a, 0 0 0 4px rgb(var(--rgbAccent));
  box-shadow: 0 0 0 3px #22223a, 0 0 0 5px rgb(var(--rgbAccent));
  outline: none;
  border-radius: 2px;
  z-index: 1090;
  position: relative;
}
.card h2 {
  font-size: 1.25rem;
  margin: 0;
}
.card .card-content {
  padding: 2rem;
  min-height: 110px;
  padding-bottom: 1rem;
  /* height: 100%; */
}
.card .card-content .kicker {
  color: rgb(var(--colorTextBody));
  margin-top: 0;
  text-transform: uppercase;
  letter-spacing: 0.16rem;
  font-size: 0.75rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
}
.card .card-content p {
  color: rgb(var(--colorTextBody));
}
.card .card-content p:last-of-type {
  margin-bottom: 0;
}
.card .card-image img {
  width: 100%;
  height: auto;
}
.card .card-footer {
  position: relative;
}
.card .card-footer .icon {
  position: absolute;
  right: 2rem;
  max-width: 20px;
  top: 50%;
  margin-top: -10px;
  transition: color 0.3s;
}
.card .card-footer a {
  background: transparent;
  border: none;
  color: rgb(var(--colorTextBody));
  padding: 1.25rem 2rem;
  width: 100%;
  text-align: left;
  display: block;
  opacity: 0;
  position: relative;
  top: 3px;
  font-weight: 400;
  transition: opacity 0.3s, top 0.3s ease 0s;
}
.card .card-footer a:hover,
.card .card-footer a:focus {
  text-decoration: none;
  opacity: 1;
  top: 0;
}
.card .card-footer a:focus {
  box-shadow: inset 0 0 0 2px rgb(var(--rgbAccent));
  outline: none;
  border-radius: 2px;
  z-index: 1090;
  position: relative;
}
.card .card-footer:after {
  content: '';
  width: 0%;
  height: 3px;
  background: rgb(var(--rgbAccent));
  position: absolute;
  bottom: 0;
  left: 0;
  transition: width 0.3s;
}
.card .card-footer:hover:after {
  width: 100%;
}
.card:hover .card-footer .icon,
.card:focus .card-footer .icon {
  color: rgb(var(--rgbAccent));
}
.card:hover .card-footer a,
.card:focus .card-footer a {
  opacity: 1;
  top: 0;
}
.card:hover .card-footer:after,
.card:focus .card-footer:after {
  width: 100%;
}

.otherProjHeading {
  color: rgb(var(--rgbText));
  /* text-align: center; */
}

.otherProjTitle {
  color: rgb(var(--rgbText));
  padding-bottom: var(--spaceM);
}

.otherProjDescription {
  font-size: 16px;
  padding-bottom: var(--spaceM);
  color: rgb(var(--colorTextBody));
}
